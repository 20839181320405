<template>
  <FormField
    :id="input?.id"
    :class="classField"
    :label="label"
    :isRequired="isRequired"
    :isLabelVisible="isLabelVisible"
    :isError="isError"
    :errorFixed="errorFixed"
    :errorMessage="errorMessage"
  >
    <input
      ref="input"
      v-uid
      v-bind="$attrs"
      :value="modelValue"
      :disabled="isDisabled"
      :type="type"
      :class="{
        inputPurple: style === 'purple',
        isError,
        isDisabled,
        'pr-18': $slots.suffix
      }"
      @input="updateValue"
    />
    <div v-if="$slots.suffix" class="absolute right-5 top-1/2 -translate-y-1/2 text-[16px]">
      <slot name="suffix" />
    </div>
  </FormField>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
export type InputStyles = 'gray' | 'purple'

interface InputProps {
  classField?: string
  label: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: string
  isDisabled?: boolean
  isError?: boolean
  errorFixed?: boolean
  errorMessage?: string
  type?: 'text' | 'email' | 'hidden' | 'search' | 'number' | 'tel' | 'url'
  style?: InputStyles
}

withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  modelValue: '',
  type: 'text',
  isDisabled: false,
  isError: false,
  errorFixed: true,
  style: 'gray'
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}

const input = ref<null | HTMLElement>(null)
</script>
