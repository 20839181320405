<template>
  <div class="mb-5 md:mb-2">
    <label
      v-if="label"
      :for="id"
      :class="[
        isLabelVisible
          ? 'mb-2.5px inline-block text-[16px] font-medium md:leading-none'
          : 'sr-only',
        {
          'cursor-pointer': id,
          'text-neutral-500': isDisabled
        },
        classLabel
      ]"
      >{{ label }}<span v-if="isRequired" class="pl-px text-orange">*</span></label
    >
    <div class="relative">
      <slot />
    </div>
    <div
      class="grid w-full grid-cols-2 items-center text-[14px]"
      :class="{ 'md:min-h-[24px]': errorFixed }"
    >
      <Transition name="label">
        <div
          v-if="isError && isErrorMessageVisible"
          class="mt-2px text-red md:mt-0"
          :class="[
            slots.bottomEnd ? 'col-span-1' : 'col-span-2',
            {
              'md:!mt-2px': !errorFixed
            }
          ]"
        >
          {{ errorMessage ? errorMessage : $t('forms.errorMessage') }}
        </div>
      </Transition>
      <div v-if="slots.bottomEnd" class="col-span-1 col-start-2 mt-2px text-right md:mt-0">
        <slot name="bottomEnd" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface InputProps {
  id?: string
  label?: string
  isLabelVisible?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  isError?: boolean
  errorFixed?: boolean
  errorMessage?: string
  isErrorMessageVisible?: boolean
  classLabel?: string
}

withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  isDisabled: false,
  isError: false,
  errorFixed: true,
  isRequired: false,
  isErrorMessageVisible: true
})

const slots = useSlots()
</script>
